/* * * * * * * * * *
+320px :: 20em
+480px :: 30em
+600px :: 37.50em
+768px :: 48em
+992px :: 62em
+1024px :: 64em
+1140px :: 71.250em
+1224px :: 76.5em
+1382px :: 86.375em
* * * * * * * * * */
/* Media Queries*/
/* Phones */
/* Tablets*/
/* Tablets Horizontal*/
/* Desktops, slightly less large devices */
/* Desktops, large devices */
/* Desktops, very large devices */
/* -----------------------------
   FUNCTIONS
   ----------------------------- */
/*
Removes the unit (e.g. px, em, rem) from a value, returning the number only.
@param {Number} $num - Number to strip unit from.
@returns {Number} The same number, sans unit.
*/
/*
Converts one or more pixel values into matching rem values.
@param {Number|List} $values - One or more values to convert. Be sure to separate them with spaces and not commas. If you need to convert a comma-separated list, wrap the list in parentheses.

@param {Number} $base [null] - The base value to use when calculating the `rem`. If you're using Foundation out of the box, this is 16px. If this parameter is `null`, the function will reference the `$base-font-size` variable as the base.

@returns {List} A list of converted values.
*/
/*
Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the rem value is taken from the `$global-font-size` variable.
@access private
@param {Number} $value - Pixel value to convert.
@param {Number} $base [null] - Base for pixel conversion.
@returns {Number} A number in rems, calculated based on the given value and the base pixel value. rem values are passed through as is.
*/
.conteneur-formulaire {
  position: absolute;
  z-index: 10;
  width: 100%;
  margin: 0 auto;
  top: 0px;
}

.fiche-conseiller-container {
  background-color: #fff;
}
.fiche-conseiller-container .titre-conseiller {
  display: block;
  max-width: 410px;
}
.fiche-conseiller-container .a-propos-contact {
  margin-top: 1em;
}
.fiche-conseiller-container #fiche-conseiller-hero .nom-conseiller {
  font-size: 2.5rem;
  color: #003da5;
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 4px;
}
@media screen and (min-width: 48em) {
  .fiche-conseiller-container #fiche-conseiller-hero .nom-conseiller {
    max-width: 350px;
  }
}
@media screen and (min-width: 62.5em) {
  .fiche-conseiller-container #fiche-conseiller-hero .nom-conseiller {
    max-width: 444px;
  }
}
.fiche-conseiller-container #fiche-conseiller-hero .diplome-conseiller {
  font-size: 1.214rem;
}
.fiche-conseiller-container #fiche-conseiller-hero .agence-conseiller {
  font-weight: normal;
}
.fiche-conseiller-container #fiche-conseiller-hero #zone-outils-hero {
  margin-top: 48px;
}
@media screen and (min-width: 48em) {
  .fiche-conseiller-container #fiche-conseiller-hero #zone-outils-hero {
    margin-top: 96px;
  }
}
@media screen and (min-width: 62.5em) {
  .fiche-conseiller-container #fiche-conseiller-hero #zone-outils-hero {
    margin-top: 113px;
  }
}
.fiche-conseiller-container #fiche-conseiller-hero #zone-outils-hero .bloc-bta-wrapper {
  background-color: rgba(0, 61, 165, 0.84);
  margin: 0;
  padding: 36px 36px;
}
@media screen and (min-width: 48em) {
  .fiche-conseiller-container #fiche-conseiller-hero #zone-outils-hero .bloc-bta-wrapper {
    display: table;
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 860px) {
  .fiche-conseiller-container #fiche-conseiller-hero #zone-outils-hero .bloc-bta-wrapper {
    padding: 36px 16px;
  }
}
@media screen and (min-width: 48em) {
  .fiche-conseiller-container #fiche-conseiller-hero #zone-outils-hero .bloc-bta-wrapper .bloc-bta-container {
    display: table-cell;
    vertical-align: middle;
  }
}
.fiche-conseiller-container #fiche-conseiller-hero #zone-outils-hero .bloc-bta-wrapper .bloc-bta-container .bloc-boutons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
}
.fiche-conseiller-container #fiche-conseiller-hero #zone-outils-hero .bloc-bta-wrapper .bloc-bta-container .bloc-boutons .ia-button-primary-solid {
  font-weight: bold;
}
.fiche-conseiller-container #fiche-conseiller-hero #zone-outils-hero #bloc-publicitaire-hero {
  background-color: rgba(255, 255, 255, 0.75);
  border: solid 6px #ffffff;
  padding: 30px 16px;
  position: relative;
}
@media screen and (min-width: 48em) {
  .fiche-conseiller-container #fiche-conseiller-hero #zone-outils-hero #bloc-publicitaire-hero {
    padding: 30px 32px 106px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 860px) {
  .fiche-conseiller-container #fiche-conseiller-hero #zone-outils-hero #bloc-publicitaire-hero {
    padding: 30px 16px 106px;
  }
}
@media screen and (min-width: 62.5em) {
  .fiche-conseiller-container #fiche-conseiller-hero #zone-outils-hero #bloc-publicitaire-hero {
    float: right;
    max-width: 66.66%;
  }
}
.fiche-conseiller-container #fiche-conseiller-hero #zone-outils-hero #bloc-publicitaire-hero h2 {
  margin-bottom: 16px;
}
.fiche-conseiller-container #fiche-conseiller-hero #zone-outils-hero #bloc-publicitaire-hero p {
  font-size: 1.214rem;
  margin: 0;
}
.fiche-conseiller-container #fiche-conseiller-hero #zone-outils-hero #bloc-publicitaire-hero .bloc-publicitaire-hero-zone-btn {
  padding-top: 24px;
}
@media screen and (min-width: 48em) {
  .fiche-conseiller-container #fiche-conseiller-hero #zone-outils-hero #bloc-publicitaire-hero .bloc-publicitaire-hero-zone-btn {
    width: 100%;
    position: absolute;
    bottom: 30px;
    left: 0;
    padding: 0 32px;
  }
}
.fiche-conseiller-container #fiche-conseiller-hero #zone-outils-hero #bloc-publicitaire-hero .bloc-publicitaire-hero-zone-btn .btn-inverse {
  margin-top: 26px;
}
@media screen and (min-width: 48em) {
  .fiche-conseiller-container #fiche-conseiller-hero #zone-outils-hero #bloc-publicitaire-hero .bloc-publicitaire-hero-zone-btn .btn-inverse {
    margin-top: 0;
  }
}
.fiche-conseiller-container #fiche-conseiller-a-propos {
  -webkit-box-shadow: 0 0 68px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 68px 0 rgba(0, 0, 0, 0.08);
}
.fiche-conseiller-container #fiche-conseiller-a-propos img {
  width: 100%;
  height: auto;
  max-width: 343px;
}
.fiche-conseiller-container #zone-fiche-conseiller-conseils-outils .titre-liste-liens h3 {
  font-size: 1.714rem;
  margin: 20px 0;
  text-transform: uppercase;
}
.fiche-conseiller-container #zone-fiche-conseiller-offre-service {
  background-image: url("/-/media/files/IA/fiches-conseillers/fonds/FICHES-Splash-1.jpg");
}
.fiche-conseiller-container .zone-grille-iaah {
  background-image: url("/-/media/files/IA/fiches-conseillers/fonds/Sectio-iAAH-Soumission_FR.jpg");
  padding-top: 284px;
  background-size: 1100px auto;
  background-position: -450px 0;
  background-repeat: no-repeat;
}
.fiche-conseiller-container .zone-grille-iaah .padding-large-standard-48 {
  padding-top: 24px;
}
.fiche-conseiller-container .zone-grille-iaah .bloc-contenu-bloc-iaah {
  max-width: 443px;
  width: 100%;
}
@media screen and (min-width: 37.5em) {
  .fiche-conseiller-container .zone-grille-iaah {
    padding-top: 0;
    background-size: 1050px auto;
    background-position: -68px 20px;
  }
  .fiche-conseiller-container .zone-grille-iaah .bloc-contenu-bloc-iaah {
    max-width: 352px;
  }
  .fiche-conseiller-container .zone-grille-iaah .padding-large-standard-48 {
    padding-top: 48px;
  }
}
@media screen and (min-width: 48em) {
  .fiche-conseiller-container .zone-grille-iaah {
    background-position: -68px 0;
  }
}
@media screen and (min-width: 62.5em) {
  .fiche-conseiller-container .zone-grille-iaah {
    padding-top: 48px;
    background-size: cover;
    background-position: 100px 0;
  }
  .fiche-conseiller-container .zone-grille-iaah .bloc-contenu-bloc-iaah {
    max-width: 443px;
  }
  .fiche-conseiller-container .zone-grille-iaah .padding-large-standard-48 {
    padding-top: 0;
  }
}
.fiche-conseiller-container #zone-fiche-conseiller-gignac {
  background-image: url("/-/media/files/IA/fiches-conseillers/fonds/FICHES-Splash-2.jpg");
}
.fiche-conseiller-container #zone-fiche-conseiller-gignac hr {
  margin: 15px 0 24px;
}
.fiche-conseiller-container #zone-fiche-conseiller-gignac .date-video {
  margin-bottom: 4px;
  display: block;
  font-weight: 400;
}
.fiche-conseiller-container #zone-fiche-conseiller-gignac .titre-video-principale {
  font-family: "Montserrat";
  font-size: 1.714rem;
  margin: 0 0 24px;
  font-weight: 600;
}
.fiche-conseiller-container #zone-fiche-conseiller-gignac .lien-vignette-video {
  display: block;
  text-decoration: none;
  color: #1b1b1b;
}
.fiche-conseiller-container #zone-fiche-conseiller-gignac .lien-vignette-video:hover h4 {
  text-decoration: underline;
}
@media screen and (min-width: 62.5em) {
  .fiche-conseiller-container #zone-fiche-conseiller-gignac .deux-colonnes-table {
    display: table;
    border-spacing: 32px;
    width: auto;
    margin: -32px;
  }
}
@media screen and (min-width: 62.5em) {
  .fiche-conseiller-container #zone-fiche-conseiller-gignac .deux-colonnes-table .deux-colonnes-table-row {
    display: table-row;
  }
}
.fiche-conseiller-container #zone-fiche-conseiller-gignac .deux-colonnes-table .deux-colonnes-table-row .deux-colonnes-table-cell {
  margin-bottom: 36px;
}
.fiche-conseiller-container #zone-fiche-conseiller-gignac .deux-colonnes-table .deux-colonnes-table-row .deux-colonnes-table-cell:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 62.5em) {
  .fiche-conseiller-container #zone-fiche-conseiller-gignac .deux-colonnes-table .deux-colonnes-table-row .deux-colonnes-table-cell {
    display: table-cell;
    width: 50%;
    background-color: #fff;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 999px) {
  .fiche-conseiller-container #zone-fiche-conseiller-gignac .deux-colonnes-table .deux-colonnes-table-row .deux-colonnes-table-cell .bloc-contenu {
    background-color: #fff;
  }
}
.fiche-conseiller-container #zone-fiche-conseiller-gignac .liste-vignettes-video .vignette-video-facebook-container {
  border-spacing: 0;
  margin-bottom: 24px;
}
.fiche-conseiller-container #zone-fiche-conseiller-gignac .liste-vignettes-video .vignette-video-facebook-container:last-child {
  margin-bottom: 0;
}
.fiche-conseiller-container #zone-fiche-conseiller-gignac .liste-vignettes-video .vignette-video-facebook-container .lien-vignette-video h4 {
  margin: 0 0 18px;
}
@media screen and (min-width: 48em) {
  .fiche-conseiller-container #zone-fiche-conseiller-gignac .liste-vignettes-video .vignette-video-facebook-container .lien-vignette-video h4 {
    margin: 0;
  }
}
.fiche-conseiller-container #zone-fiche-conseiller-gignac .liste-vignettes-video .vignette-video-facebook-container .lien-vignette-video .image-vignette-video {
  position: relative;
  max-width: 200px;
}
.fiche-conseiller-container #zone-fiche-conseiller-gignac .liste-vignettes-video .vignette-video-facebook-container .lien-vignette-video .image-vignette-video:before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: "";
  display: block;
  background-image: url(https://static.xx.fbcdn.net/rsrc.php/v3/y4/r/_bKP_jiRZ_z.png);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 0 0;
  background-color: transparent;
  border: none;
  height: 20px;
  width: 20px;
}
@media screen and (min-width: 48em) {
  .fiche-conseiller-container #zone-fiche-conseiller-gignac .liste-vignettes-video .vignette-video-facebook-container .lien-vignette-video .image-vignette-video {
    float: right;
    width: 134px;
    vertical-align: top;
  }
  .fiche-conseiller-container #zone-fiche-conseiller-gignac .liste-vignettes-video .vignette-video-facebook-container .lien-vignette-video .titre-vignette-video {
    float: right;
    width: calc(100% - 134px);
    padding-left: 16px;
    vertical-align: top;
  }
}
.fiche-conseiller-container #zone-fiche-conseiller-gignac .note-bas-page-gignac {
  width: auto;
  padding: 36px 16px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 0 -16px;
}
@media screen and (min-width: 48em) {
  .fiche-conseiller-container #zone-fiche-conseiller-gignac .note-bas-page-gignac {
    margin: 0 -32px;
    padding: 36px 32px;
  }
}
.fiche-conseiller-container #zone-fiche-conseiller-gignac .note-bas-page-gignac .row {
  max-width: 1140px;
  margin: 0 auto;
}
.fiche-conseiller-container #zone-fiche-conseiller-gignac .note-bas-page-gignac p {
  margin: 0;
}
.fiche-conseiller-container ul.liste-liens li {
  margin-bottom: 12px;
}
.fiche-conseiller-container ul.liste-liens li:last-child {
  margin-bottom: 0 !important;
}
.fiche-conseiller-container ul.liste-liens.texte-blanc li {
  margin-bottom: 16px;
}
.fiche-conseiller-container ul.liste-liens.texte-blanc li a {
  font-weight: 400;
}
.fiche-conseiller-container #zone-grille-pourquoi .bloc-contenu-bloc-portail-client h5 {
  margin-top: 0;
}
@media screen and (min-width: 62.5em) {
  .fiche-conseiller-container #zone-grille-pourquoi .bloc-contenu {
    position: relative;
    height: 100%;
    padding-bottom: 88px;
  }
  .fiche-conseiller-container #zone-grille-pourquoi .bloc-contenu ul {
    margin-bottom: 0;
  }
  .fiche-conseiller-container #zone-grille-pourquoi .bloc-contenu.bloc-contenu-bloc-portail-client {
    padding-bottom: 76px;
  }
  .fiche-conseiller-container #zone-grille-pourquoi .bloc-contenu.bloc-contenu-bloc-portail-client p {
    margin-bottom: 0;
  }
  .fiche-conseiller-container #zone-grille-pourquoi .bloc-contenu.bloc-contenu-bloc-portail-client h2 {
    margin-bottom: 16px;
  }
  .fiche-conseiller-container #zone-grille-pourquoi .bloc-contenu.bloc-contenu-bloc-portail-client h5 {
    margin-top: 0;
  }
  .fiche-conseiller-container #zone-grille-pourquoi .btn {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    display: block;
  }
}

.contact-conseiller-container {
  background-color: #fff;
  position: relative;
}
.contact-conseiller-container #en-tete-titre a {
  font-weight: 600;
}
.contact-conseiller-container #en-tete-titre h1 {
  color: #003da5;
  font-size: 35px;
  font-weight: 900;
  margin-bottom: 0px;
  margin-top: 24px;
  text-transform: uppercase;
}
@media screen and (max-width: 999px) {
  .contact-conseiller-container #zone-contact-conseiller > .row > .col-med-1-3 {
    width: 100% !important;
  }
}
.contact-conseiller-container #googleMap {
  height: 400px;
  background-color: #fff;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 62.5em) {
  .contact-conseiller-container #googleMap {
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
  }
}
.contact-conseiller-container .gm-style-pbc {
  display: none !important;
}
@media screen and (max-width: 999px) {
  .contact-conseiller-container #zone-contact-conseiller {
    padding: 0 !important;
  }
}
.contact-conseiller-container #zone-contact-conseiller #bloc-contact-conseiller {
  position: relative;
  z-index: 2;
  padding: 48px 16px 36px;
  -webkit-box-shadow: inset 1px 6px 12px -6px rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 6px 12px -6px rgba(0, 0, 0, 0.2);
}
.contact-conseiller-container #zone-contact-conseiller #bloc-contact-conseiller:after {
  display: none;
}
@media screen and (min-width: 48em) {
  .contact-conseiller-container #zone-contact-conseiller #bloc-contact-conseiller {
    padding: 48px 32px 36px;
  }
}
@media screen and (min-width: 62.5em) {
  .contact-conseiller-container #zone-contact-conseiller #bloc-contact-conseiller {
    -webkit-box-shadow: 0 0 68px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 68px 0 rgba(0, 0, 0, 0.1);
    padding: 48px 16px 36px;
  }
  .contact-conseiller-container #zone-contact-conseiller #bloc-contact-conseiller:after {
    display: block;
  }
}
.contact-conseiller-container #zone-contact-conseiller #bloc-contact-conseiller .nom-contact-conseiller {
  font-size: 2rem;
  margin-bottom: 0;
}
.contact-conseiller-container #zone-contact-conseiller #bloc-contact-conseiller .diplome-conseiller {
  font-size: 1.214rem;
}
.contact-conseiller-container #zone-contact-conseiller #bloc-contact-conseiller h4 {
  margin: 16px 0;
}
.contact-conseiller-container #zone-contact-conseiller #bloc-contact-conseiller h4 .titre-conseiller {
  display: block;
}
.contact-conseiller-container #zone-contact-conseiller #bloc-contact-conseiller .agence-conseiller {
  font-weight: 400;
  font-size: 1em !important;
}
.contact-conseiller-container #zone-contact-conseiller #bloc-contact-conseiller hr {
  margin: 36px 0;
}
.contact-conseiller-container #zone-contact-conseiller #bloc-contact-conseiller .commun-pages-reseaux-sociaux h5 {
  margin: 0 0 16px;
}
.contact-conseiller-container #zone-contact-conseiller #bloc-contact-conseiller .commun-pages-reseaux-sociaux ul li {
  margin-right: 25px;
}
.contact-conseiller-container #zone-contact-conseiller #bloc-contact-conseiller .commun-pages-reseaux-sociaux ul li svg {
  margin: 0;
}
.contact-conseiller-container #zone-contact-conseiller #bloc-contact-conseiller .commun-pages-reseaux-sociaux ul li svg.commun-social-facebook {
  margin-right: -10px;
}
.contact-conseiller-container #zone-contact-conseiller #bloc-contact-conseiller .commun-pages-reseaux-sociaux ul li:last-child svg {
  margin-right: 0;
}
.contact-conseiller-container #zone-contact-conseiller #bloc-contact-conseiller .telephone-poste {
  font-size: 1rem;
  margin-left: 5px;
  position: relative;
  display: inline-block;
  top: -1px;
}

html:lang(en) .zone-grille-iaah {
  background-image: url("/-/media/files/IA/fiches-conseillers/fonds/Sectio-iAAH-Soumission_EN.jpg");
}

@media screen and (min-width: 71.25em) {
  .carousel-publicitaire .swiper-slide-content {
    background-position: bottom left;
  }
}

/*  ============================================================
+Page Confirmation
============================================================ */
#zone-formulaire-fiche-conseiller .bloc-contenu {
  margin-bottom: 0;
}
#zone-formulaire-fiche-conseiller .btn-retour {
  margin-top: 36px;
}

/*  ============================================================
+Confirmation Courriel
============================================================ */
.confirmation .container {
  padding: 0 !important;
  width: 100% !important;
}

.confirmation-courriel {
  padding-bottom: 25px;
}
.confirmation-courriel p.info {
  margin-top: 30px;
}

.confirmation-courriel-bloc .courriel-message {
  padding: 20px 0 20px 5px;
  margin-left: 0;
}
@media only screen and (min-width: 48em) {
  .confirmation-courriel-bloc .courriel-message {
    padding: 36px 20px;
    margin-left: 100px;
  }
}
.confirmation-courriel-bloc .courriel-message p.merci {
  font-family: "Montserrat";
  font-weight: 700;
  margin: 0;
  font-size: 2.29em;
}
.confirmation-courriel-bloc .courriel-message p.message {
  margin: 0;
  font-size: 1.43em;
}
.confirmation-courriel-bloc.valide {
  border-top: 1px solid #afbdc3;
  border-bottom: 1px solid #afbdc3;
}
.confirmation-courriel-bloc.valide .courriel-message p.merci {
  color: #3a9b4d;
}
.confirmation-courriel-bloc.invalide {
  border-top: 1px solid #d30023;
  border-bottom: 1px solid #d30023;
}
.confirmation-courriel-bloc.invalide .courriel-message p.merci {
  color: #d30023;
}

.icone-courriel-confirmation {
  width: 100px;
  height: 100px;
  display: block;
  float: none;
  padding-top: 15px;
  /*  768px */
}
@media only screen and (min-width: 48em) {
  .icone-courriel-confirmation {
    width: 100px;
    height: 100px;
    display: block;
    float: left;
    padding-top: 34px;
  }
}
.icone-courriel-confirmation:before {
  font-size: 90px;
  font-size: 7rem;
}
.valide .icone-courriel-confirmation:before {
  content: "\e65e";
  color: #3a9b4d;
}
.invalide .icone-courriel-confirmation:before {
  content: "\e65f";
  color: #d30023;
}

.cabinet-container {
  padding: 8px 0;
}
.cabinet-container .cabinet {
  display: block;
}
.cabinet-container .cabinet:empty {
  display: none;
}

form[data-utag-fname=form_advisor_file] .fond-formulaire {
  padding-bottom: 0 !important;
}
form[data-utag-fname=form_advisor_file] #zoneTemoignageConseiller {
  display: none;
}

.page-formulaire-fiche-conseiller .main {
  background-color: #eff1f4 !important;
}
.page-formulaire-fiche-conseiller #en-tete .logo_ia_D {
  width: auto !important;
}